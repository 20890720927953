@import '../core/alerts';

// This file has been autogenerated by grunt task lessToSass. Any changes will be overwritten.

.alert {
    border: 0;
    border-radius: 3px;
    position: relative;
    padding: 20px 15px;
    line-height: 20px;

    b {
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        font-size: $font-size-small;
    }
    // SASS conversion note: please mirror any content change in _mixins-shared.scss alert-variations-content
    @include alert-variations(unquote('.alert'), unquote(''), $mdb-text-color-light);

    &-info,
    &-danger,
    &-warning,
    &-success,
    &-rose {
        color: $mdb-text-color-light;
    }

    &-default {
        a,
        .alert-link {
            color: $mdb-text-color-primary;
        }
    }

    span {
        display: block;
        max-width: 89%;
    }

    &.alert-danger {
        @include shadow-alert-color($brand-danger);
        @include alert-icon-color($brand-danger);
    }
    &.alert-warning {
        @include shadow-alert-color($brand-warning);
        @include alert-icon-color($brand-warning);
    }
    &.alert-success {
        @include shadow-alert-color($brand-success);
        @include alert-icon-color($brand-success);
    }
    &.alert-info {
        @include shadow-alert-color($brand-info);
        @include alert-icon-color($brand-info);
    }
    &.alert-primary {
        @include shadow-alert-color($brand-primary);
        @include alert-icon-color($brand-primary);
    }
    &.alert-rose {
        @include shadow-alert-color($brand-rose);
        @include alert-icon-color($brand-rose);
    }

    &.alert-with-icon {
        margin-top: 43px;
        padding-left: 66px;

        i[data-notify='icon'] {
            display: block;
            left: 15px;
            position: absolute;
            margin-top: -39px;
            font-size: 20px;
            background-color: $white-color;
            padding: 9px;
            border-radius: 50%;
            max-width: 38px;
            @include shadow-big();
        }
    }

    .close {
        line-height: 0.5;
        i {
            color: $white-color;
            font-size: 11px;
        }
    }

    i[data-notify='icon'] {
        display: none;
    }

    .alert-icon {
        display: block;
        float: left;
        margin-right: $margin-base;

        i {
            margin-top: -7px;
            top: 5px;
            position: relative;
        }
    }

    [data-notify='dismiss'] {
        margin-right: 5px;
    }
}

.places-buttons .btn {
    margin-bottom: 30px;
}
