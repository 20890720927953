@import 'variables';

#org-school-admin-layout {
    padding-bottom: 1px;
    margin: 5px auto 0;
}

#org-school-admin-layout p.simple-text,
#org-school-admin-layout div p.simple-text {
    font-size: $font-size-large;
    margin-top: 10px;
    text-align: center;
}