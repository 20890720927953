@use '@angular/material' as mat;
.mat-focused .mat-form-field-required-marker {
    color: mat.get-color-from-palette(map-get($custom-theme, warn), text);
}

// If the font-size of an <input> is 16px or larger, Safari on iOS in mobile
// will focus into the input normally. But as soon as the font-size is 15px or less,
// the viewport will zoom into that input. To prevent a zoom into, We have increased
// a font-size for mobile devices.
@media only screen and (max-device-width: 767px) {
    input.mat-input-element {
        font-size: 16px;
    }
    textarea.mat-input-element {
        font-size: 16px;
    }
}

apps-preview-fillableform {
    .mat-form-field {
        & > .mat-form-field-wrapper {
            &:has(.mat-form-field-flex > .mat-form-field-infix > input.mat-input-element[readonly], textarea.mat-input-element[readonly])
                > .mat-form-field-underline {
                background-color: transparent;
                background-image: linear-gradient(to right, rgba(0, 0, 0, 0.42) 0%, rgba(0, 0, 0, 0.42) 33%, transparent 0%);
                background-size: 4px 100%;
                background-repeat: repeat-x;
            }
        }
    }
}
