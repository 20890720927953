@import 'variables';

.org-families-list {
    .table-responsive {
        .mat-table {
            tr {
                th,
                td {
                    &:nth-last-of-type(-n + 2) {
                        text-align: center;
                    }
                }
            }
        }
    }
}
