@function series-class-name($name) {
    @return 'series-' + $name;
}

@mixin series-style($class-name, $color) {
    .#{$class-name} {
        @include ct-chart-series-color($color);
    }

    .legend-chart,
    .unClickableLegend {
        .#{$class-name} {
            color: $color;
        }
    }
}
