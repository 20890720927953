/*!

 =========================================================
 * Material Dashboard PRO Angular - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-angular2
 * Copyright 2018 Creative Tim (https://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
// Use our custom theme, instead of prebuilt one. See ET-4020
// @import "@angular/material/prebuilt-themes/indigo-pink.css";

// @import 'bootstrap/scss/bootstrap';
@import 'bootstrap/scss/functions';
@import 'core/variables';
@import 'bootstrap/scss/variables';
@import 'core/mixins';
@import 'bootstrap/scss/mixins';

@import 'mdb-ui-kit/src/scss/mdb.free.scss';

@import 'core/reboot';

// Core Components
@import 'core/layout';
@import 'core/buttons';
@import 'core/checkboxes';
@import 'dashboard/cards';
@import 'core/custom-forms';
@import 'core/switches';
@import 'core/example-pages';
@import 'core/radios';
@import 'core/carousel';
@import 'dashboard/forms';
@import 'core/input-group';
@import 'core/list-group';
@import 'core/nav';
@import 'core/images';
@import 'dashboard/navbar';
@import 'core/badges';
@import 'dashboard/alerts';
@import 'core/pagination';
@import 'core/pills';
@import 'core/info-areas';
@import 'core/type';
@import 'core/tabs';
@import 'core/footers';
@import 'core/tooltip';
@import 'core/popover';
@import 'core/modal';
@import 'core/dropdown';
@import 'core/headers';
@import 'core/drawer';
@import 'core/progress';
@import 'core/togglebutton';
@import 'core/ripples';
@import 'dashboard/rtl';
@import 'dashboard/sidebar-and-main-panel';
@import 'dashboard/timeline';
@import 'dashboard/fixed-plugin';
@import 'dashboard/tables';
@import 'dashboard/misc';
@import 'dashboard/pages';
@import 'dashboard/mat-menu';

// Components for PRO
@import 'dashboard/social-buttons';
@import 'dashboard/fileupload';

//plugin css
@import 'plugins/plugin-nouislider';
@import 'plugins/animate';
@import 'plugins/sweetalert2';
@import 'plugins/plugin-datetime-picker';
@import 'plugins/wizard-card';
@import 'plugins/chartist';
@import 'chartist/dist/scss/chartist';
@import 'plugins/perfect-scrollbar';
@import 'plugins/tiny-mce-editor';

@import 'dashboard/responsive';