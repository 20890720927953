// http://codepen.io/rosskevin/pen/EPPwRR?editors=110
.switch {
    label {
        position: relative;
        padding-left: $bmd-switch-width + $bmd-switch-label-padding; // absolutely positioned so add the radio size

        .bmd-switch-track {
            position: absolute;
            top: calc-top($line-height-base, $font-size-base, $bmd-switch-height);
            left: 0;
            display: inline-block;
            width: $bmd-switch-width;
            height: $bmd-switch-height;
            cursor: pointer;
            background-image: linear-gradient(
                to right,
                $bmd-switch-unchecked-bg 0%,
                $bmd-switch-unchecked-bg 50%,
                $bmd-switch-checked-bg 50%,
                $bmd-switch-checked-bg 100%
            );
            background-position: 0%;
            background-size: $bmd-switch-width * 2 $bmd-switch-height;
            border-radius: $bmd-switch-width;
            transition: background-position 0.2s ease-in;

            &::after {
                position: absolute;
                top: 50%;
                left: 0;
                display: block;
                align-self: center;
                width: $bmd-switch-handle-size;
                height: $bmd-switch-handle-size;
                content: '';
                background: $bmd-switch-handle-unchecked-bg;
                border-radius: 100%;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
                transition: left 0.2s ease-in, background-color 0.2s ease-in, transform 0.3s ease;
                transform: translateY(-50%);
            }

            // Jelly effect on click
            &:active::after {
                transform: translateY(-50%) scale3d(1.15, 0.85, 1);
            }
        }

        input {
            // Hide original checkbox, but don't use `display: none` to allow focus on it using keyboard
            position: absolute;
            display: block;
            width: 0;
            height: 0;
            opacity: 0;

            &:checked {
                + .bmd-switch-track {
                    background-position: -100%;
                    &::after {
                        left: unquote('calc(100% - #{$bmd-switch-handle-size})'); //calc(100% - $bmd-switch-handle-size);
                        background-color: $bmd-switch-handle-checked-bg;
                    }
                }
            }

            &:disabled {
                // No jelly effect on disabled switchs
                + .bmd-switch-track:active::after {
                    transform: translateY(-50%);
                }

                + .bmd-switch-track {
                    cursor: default;
                    background: $bmd-switch-disabled-bg;
                    &::after {
                        background: $bmd-switch-handle-disabled-bg;
                    }
                }
            }
        }
    }
}
