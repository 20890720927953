.footer {
    .row {
        margin: 0;
        .col-sm-12 {
            padding-left: 0;
        }
    }
    .copyright {
        font-size: $font-size-small;
        font-weight: $font-weight-bold;
        margin: 0;
    }
    ul {
        li {
            a {
                font-size: $font-size-small;
                &:first-child {
                    padding-left: 0;
                }
            }
        }
    }
}
.login-page {
    .footer {
        .container {
            padding: 0 15px;
        }
    }
}
