// Styling for a "fake" link
// TODO we should be using real `<a>` or `<button>` instead of restyling other HTML elements
// Making fake links is a serious accessibility problem https://a11y-101.com/design/button-vs-link
.l-cs {
    cursor: pointer;
    color: $link-color;
    font-weight: inherit;
}

.sticky-button-bar {
    background-color: $bg-light;
}

body {
    background-color: $bg-light;

    &.inheritCursors * {
        cursor: inherit !important;
    }
}

td .list-unstyled {
    margin-bottom: 0;
}

.pac-container {
    z-index: 1100;
}

.ct-label {
    color: var(--bs-body-color) !important;
}
.m-250 {
    margin: 250px;
}

a .material-icons-outlined {
    vertical-align: middle;
}
