button {
    &.is-loading {
        span.spinner-border {
            display: inline-block;
        }
        &.btn-close,
        &.btn-cancel {
            span.spinner-border {
                display: none;
            }
        }
    }
    span.spinner-border {
        display: none;
    }
}
