%common-card-background {
    background-position: center center;
    background-size: cover;
    text-align: center;

    .card-body {
        position: relative;
        z-index: 2;
        min-height: 280px;
        padding-top: 40px;
        padding-bottom: 40px;
        max-width: 440px;
        margin: 0 auto;
    }

    .card-category,
    .card-description,
    small {
        color: rgba($white, 0.7) !important;
    }

    .card-title {
        color: $white-color;
        margin-top: 10px;
    }

    &:not(.card-pricing) .btn {
        margin-bottom: 0;
    }

    &:after {
        position: absolute;
        z-index: 1;
        width: 100%;
        height: 100%;
        display: block;
        left: 0;
        top: 0;
        content: '';
        background-color: rgba(0, 0, 0, 0.56);
        border-radius: $border-radius-large;
    }
}

.card-background {
    @extend %common-card-background;
}
