mat-radio-group {
    mat-radio-button {
        margin-inline-end: 1rem;
    }
}

.mat-radio-button {
    .mat-radio-label {
        white-space: normal;
    }
}
