.card-blog {
    margin-top: 60px;

    [class*='col-'] {
        .card-header-image {
            img {
                width: 100%;
            }
        }
    }
    .carf-footer {
        .stats {
            .material-icons {
                font-size: 18px;
                position: relative;
                top: 4px;
                width: 19px;
            }
        }
    }
}
