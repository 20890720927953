app-student-enquiry-switcher {
    & > button.mat-menu-trigger {
        background-color: $concrete;
        font-weight: $font-weight-light;
        margin-inline-end: $spacer;
        mat-icon {
            color: $brand-primary;
        }
    }
}

.student-enquiry-switcher-menu {
    .mat-menu-content {
        a {
            display: flex;
            align-items: center;
            padding-right: 32px;
            font-weight: $font-weight-light;
            font-size: 15px;
            &.activeView,
            &.activeView:hover {
                background-color: rgba($brand-primary, 0.35);
            }
            mat-icon {
                font-size: 22px;
            }
            &:hover,
            &:focus {
                color: initial;
            }
        }
    }
}
