$table-header-color: $white !default;
$table-header-bg: $gray !default;
$table-header-font-size: .875rem !default;
$table-header-font-weight: $font-weight-light !default;
$table-header-border-radius: 3px !default;
$table-header-padding-x: 0px !default;
$table-header-padding-y: 8px !default;
$table-header-height: 32px !default;

$table-stats-gap: 8px !default;
$table-even-row-bg: $concrete !default;
$table-row-hover-bg: $gallery !default;

$table-row-height: 48px !default;
$table-column-padding-x: 0px !default;
$table-column-padding-y: 5px !default;

$table-sort-header-arrow-color: $bg-light !default;

$table-icon-size: 1.3rem;

$bg-alpha: 0.4 !default;