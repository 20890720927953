@import 'variables';

.org-key-metrics {
    .table-responsive {
        table {
            tr {
                th:nth-child(n + 4) {
                    & > div {
                        justify-content: center;
                    }
                }
            }
        }
    }
}
