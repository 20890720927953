@import '../core/forms';

.card {
    .card-body {
        .col-form-label,
        .label-on-right {
            padding: 16px 5px 0 0;
            text-align: right;
        }
        .label-on-right {
            text-align: left;
        }
    }
    .label-on-right {
        code {
            padding: 2px 4px;
            font-size: 90%;
            color: #c7254e;
            background-color: #f9f2f4;
            border-radius: 4px;
        }
    }
}

.card-wizard {
    .input-group .form-group {
        width: 80%;
    }
}
