$salt-box: #736176;
$concrete: #f3f3f3;
$gallery: #ececec;
$silver: #cdcdcd;

// Charts color
$astral: #337ab7; // same as text-six
$aqua-island: #add; // same as text-seven
$conifer: #ad3; // same as text-eight
$ripe-lemon: #f6d328;
$dark-blue: #0000d6; // same as text-dark-blue
$electric-violet: #5300e8; // same as text-middle-blue
$lavender-indigo: #9965f4; // same as text-light-blue

// Event Summary Table Colors
$stage-interest: $social-pinterest !default;
$stage-applicant: $yellow-700 !default;
$stage-enrolled: $brand-success !default;
$stage-declined: $astral !default;
