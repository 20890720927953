table tr td.td-actions,
mat-table mat-cell.td-actions {
    white-space: nowrap;
    .mat-button.btn,
    .mat-raised-button.btn,
    .mat-raised-button.btn:not([class*='mat-elevation-z']),
    .btn {
        &.btn-link {
            margin: $btn-link-margin-spacer-x $btn-link-margin-spacer-y;
            padding: $btn-link-padding-spacer-x $btn-link-padding-spacer-y;
            &,
            &:hover {
                background-color: $btn-link-bg;
            }
        }
    }
}

.table > thead, // For HTML table header
.mat-header-row, // Override Angular Material theme
.head, // For dashboard config
th[class*="headcol-"] // For sticky column headers in event analytics table (offset from <thead>, so it does not get a color by default)
{
    background: $table-header-bg;
}

.table > thead > tr > th, // Override Creative Tim theme
.mat-header-cell, // Override Angular Material theme
.head // For dashboard config
{
    color: $table-header-color;
    font-size: $table-header-font-size;
    font-weight: $table-header-font-weight;
}

.table-stats {
    display: flex;
    align-items: center;
    gap: $table-stats-gap;
}

#tableResponsiveAttendees table,
.table-responsive table {
    // Align checkboxes in header with other header titles
    .mat-checkbox {
        display: flex;
        label {
            margin-bottom: 0;
        }
    }
    td,
    th {
        &:first-of-type {
            padding-left: 10px;
        }
    }
    &.sticky-table-header {
        thead {
            position: sticky;
            z-index: 100;
            top: 0;
        }
    }

    // Keep height of all headers the same
    thead {
        tr {
            height: $table-header-height;
            th {
                padding: $table-header-padding-x $table-header-padding-y;
                // Use contrasting color for checkbox outline
                .mat-checkbox .mat-checkbox-inner-container .mat-checkbox-frame {
                    border-color: $table-header-color;
                }
                .mat-checkbox .mat-checkbox-layout .mat-checkbox-inner-container .mat-checkbox-checkmark-path {
                    stroke: $table-header-color !important;
                }
            }
        }
        th[class*='headcol-'] {
            height: $table-header-height + 1;
            line-height: $table-header-height + 1;
        }
    }

    // Keep height of all rows the same
    tbody {
        tr {
            height: $table-row-height;
            td {
                padding: $table-column-padding-x $table-column-padding-y;
                i.material-icons {
                    font-size: $table-icon-size;
                }
            }
            td[class*='headcol-'] {
                line-height: $table-row-height + 1; // Add line height for text align center
                height: $table-row-height + 1;
            }
        }
    }
}

// Apply a zebra row background effect in all tables except duplicate contact table.
#tableResponsiveAttendees > table,
.table-responsive:not([id='duplicateContactListTableContainer'], [id='duplicateStudentListTableContainer']) > table {
    > tbody {
        > tr {
            &:nth-child(even),
            &:nth-child(even) td[class*='headcol-'] {
                background-color: $table-even-row-bg;
            }
            &:hover,
            &:hover td[class*='headcol-'] {
                background-color: $table-row-hover-bg;
            }
            // Used for the comms audience table
            // Align expansion indicator
            .mat-expansion-panel-header .mat-expansion-indicator {
                position: absolute;
                right: 5px;
                margin-top: -4px;
            }
            // Make the panel look the same as rest of table
            .mat-expansion-panel-header,
            .mat-expansion-panel-content {
                font-weight: inherit;
                font-size: inherit;
                height: auto;
            }
            // Remove extra indentation
            .mat-expansion-panel-header,
            .mat-expansion-panel-body {
                padding: 0;
            }
            // Remove highlighting of panel header on hover
            .mat-expansion-panel-header:hover:not([aria-disabled='true']) {
                background: none;
            }
        }
    }
    // Remove border since we're using zebras
    td.mat-cell {
        border: none;
    }
    @at-root .table:not(.table-bordered) {
        td {
            border: none;
        }
    }
}

// Style table headers
#tableResponsiveAttendees,
.table-responsive {
    thead {
        tr {
            // No borders unless it is a multi-row header
            &:only-child {
                th {
                    border: none;
                }
            }
            th {
                border: 1px solid tint-color($gray-base, 53.5%);
            }

            // Style corner header cells differently
            &:first-child {
                th {
                    border-top: none;
                    &:first-child {
                        border-left: none;
                        border-top-left-radius: $table-header-border-radius;
                    }
                    &:last-child {
                        border-right: none;
                        border-top-right-radius: $table-header-border-radius;
                    }
                }
            }
            &:last-child {
                th {
                    border-bottom: none;
                    &:first-child {
                        border-left: none;
                        border-bottom-left-radius: $table-header-border-radius;
                    }
                    &:last-child {
                        border-right: none;
                        border-bottom-right-radius: $table-header-border-radius;
                    }
                }
            }
        }
    }
}

mat-table {
    mat-header-row {
        min-height: $table-header-height;
        border-radius: $table-header-border-radius;
    }
    mat-row {
        min-height: $table-row-height;
        border: none;
        &:nth-child(odd) {
            // use odd because offset by mat-header-row as the first child
            background-color: $table-even-row-bg;
        }
        &:hover {
            background-color: $table-row-hover-bg;
        }
    }
}

.mat-sort-header-arrow {
    color: $table-sort-header-arrow-color;
}

tr.row-disabled td {
    color: rgba(0, 0, 0, 0.26) !important;
}
