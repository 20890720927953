.pie-chart-tooltip {
    padding: 12px;
    border-width: 2px;
    border-style: solid;
    border-radius: 8px;
    line-height: 20px;

    span {
        font-size: 16px;
        font-weight: bold;
        white-space: normal;
    }
    label {
        font-size: 16px;
        white-space: normal;
    }
}

@each $series-name, $palette in $palettes-by-series {
    $class-name: series-class-name($series-name);
    $var-color: var(#{custom-property-name($class-name + '-color')});

    @include series-style($class-name, $var-color);
}

:root {
    // do loop inside :root to prevent repeating the CSS block
    @each $series-name, $palette in $palettes-by-series {
        @each $property in $palette-properties {
            $var-name: #{series-class-name($series-name)}-#{$property};
            $value: map-get($palette, $property) or map-get($series-defaults, $property);
            // if value is undefined, no variable will be created
            @include define-custom-property($var-name, $value);
        }
    }
}
