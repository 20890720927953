apps-readonly-form {
    @media print {
        // Avoid unnecessary page break
        .mat-expansion-panel {
            display: inline;
        }

        // Hide expansion panel arrow
        .mat-expansion-indicator,
        // Hide dropdown arrow
        .mat-select-arrow {
            display: none;
        }

        // Show all of the dropdown value
        .mat-select-min-line,
        // Show all of the form field label
        .mat-form-field-label {
            white-space: normal;
        }

        // Wrap checkbox labels that have really long words
        .mat-checkbox-label {
            overflow-wrap: anywhere;
        }
    }
}
