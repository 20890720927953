// https://www.google.com/design/spec/components/lists.html#lists-specs

// - single line 48dp
// - single line avatar 56dp
// - double line (with and w/o avatar) 72dp
// - triple line 88dp

.list-group {
    // #853 start -- https://github.com/twbs/bootstrap/pull/18981/files
    @if $enable-flex {
        display: flex;
        flex-direction: column;
    }
    // #853 end -- https://github.com/twbs/bootstrap/pull/18981/files

    flex-grow: 1;

    // Add 8dp of padding at the top and bottom of a list. One exception is at the top of a list with a subheader, because subheaders contain their own padding.
    padding: 0.5rem 0;

    // dense size variation
    &.bmd-list-group-sm {
        padding: 0.25rem 0;

        .list-group-item {
            padding: 0.5rem 1rem;
        }
    }
}

.bmd-list-group-col {
    display: flex;
    flex-direction: column;
    min-width: 0;
    //flex-shrink: 0;
}

.list-group-item {
    // #853 start -- https://github.com/twbs/bootstrap/pull/18981/files
    @if $enable-flex {
        display: flex;
        flex-flow: row wrap;
        align-items: center;
    } @else {
        display: block;
    }
    // #853 end -- https://github.com/twbs/bootstrap/pull/18981/files
    padding: 1rem;
    line-height: 1;

    .list-group-item-text {
        // word wrap? http://stackoverflow.com/a/26535469/2363935
        //flex-flow: row wrap;
        min-width: 0;
        max-height: 2.188rem; // 88dp tile total height
        // FIXME: text overflow with ellipsis is not working
        //flex: 0 1 auto; //    http://stackoverflow.com/questions/12022288/how-to-keep-a-flex-item-from-overflowing-due-to-its-text
        overflow: hidden;
        text-overflow: ellipsis;
    }

    //------
    // NOTE: there are many variations of icons/labels in a list, each one of these rules exist for a reason.

    // first icon/label on the left
    :first-child {
        margin-right: 2rem; // single line icon is 72dp to text (16 pad + 24 icon + 32 margin)
    }

    // second icon/label, or only icon/label marked as 'pull-*-right', or any direct child element marked as right
    > .pull-xs-right,
    > .pull-sm-right,
    > .pull-md-right,
    > .pull-lg-right,
    > .pull-xl-right,
    > * ~ .label:last-child,
    > * ~ .material-icons:last-child {
        margin-right: 0; // some variations actually match the first-child
        margin-left: auto;
    }

    // add the padding to icons (margin-left is used for alignment)
    .material-icons.pull-xs-right,
  // need the specificity
  .material-icons.pull-sm-right,
  .material-icons.pull-md-right,
  .material-icons.pull-lg-right,
  .material-icons.pull-xl-right,
  .material-icons ~ .material-icons:last-child {
        padding-left: 1rem; // guarantee space per spec
    }
    //------

    .list-group-item-heading {
        //font-size: 1rem;
    }

    .list-group-item-text {
        font-size: 0.875rem;
        color: $gray;
    }
}
