.char_count {
    text-align: center;
    font-size: 12px;
}

.char_count.not-valid {
    color: red;
    font-weight: bold;
}
.char_count.valid {
    color: black;
    font-weight: normal;
}
