@import 'variables';

#orgDuplicateEnquiriesPerSchoolTableContainer {
    .mat-table {
        tr {
            th:nth-last-of-type(-n + 2) {
                & > div {
                    justify-content: center;
                }
            }
            td {
                &:nth-last-of-type(-n + 2) {
                    text-align: center;
                }
            }
        }
    }
}

#orgSchoolDetailsTableContainer {
    .mat-table {
        tr {
            th:nth-last-of-type(2) {
                & > div {
                    justify-content: center;
                }
            }
            td {
                &:nth-last-of-type(2) {
                    text-align: center;
                }
            }
        }
    }
}
