@mixin bmd-tabs-color($color, $active-color, $active-border, $disabled-link-color, $disabled-link-hover-color) {
    .nav-link {
        color: $color;

        &.active {
            color: $active-color;
            border-color: $active-border;
        }

        // Disabled state lightens text and removes hover/tab effects
        &.disabled {
            color: $disabled-link-color;
        }
    }
}

@mixin set-wizard-color($color) {
    .moving-tab {
        background-color: $color;
        @include shadow-big-color($color);
    }

    .picture {
        &:hover {
            border-color: $color;
        }
    }

    .choice {
        &:hover,
        &.active {
            .icon {
                border-color: $color;
                color: $color;
            }
        }
    }

    .checkbox input[type='checkbox']:checked + .checkbox-material {
        .check {
            background-color: $color;
        }
    }

    .radio input[type='radio']:checked ~ .check {
        background-color: $color;
    }

    .radio input[type='radio']:checked ~ .circle {
        border-color: $color;
    }
}
