@media print {
    label.mat-form-field-label.mat-form-field-empty {
        transform: translateY(-1.3em) scale(0.75);
        white-space: normal;
    }
    .mat-card,
    .mat-expansion-panel-body,
    .mat-expansion-panel-header {
        padding: 0px !important;
    }
    // To keep a fieldset together and avoid break between pages for fieldset.
    fieldset {
        break-inside: avoid;
        // Avoid moving first child of fieldset into a new page.
        &:first-child {
            break-inside: auto;
        }
        // Avoid moving first child of fieldset into a new page.
        & > fieldset:first-child {
            break-inside: avoid;
        }
    }
    // To keep a material expansion panel together and avoid break between pages.
    mat-expansion-panel {
        break-inside: avoid;
    }
    .border {
        border: none !important;
    }
    .d-flex.flex-column-reverse {
        display: block !important;
    }
    header,
    footer {
        padding: 0 15px;
    }
    legend {
        display: block !important;
        position: relative !important;
    }
    fieldset {
        display: grid !important;
    }
    // To fix a overlapping text
    .mat-form-field-underline {
        position: initial !important;
    }
    .mat-form-field-subscript-wrapper {
        position: relative !important;
    }
    .fieldset {
        * {
            color: $black !important;
        }
    }
}
