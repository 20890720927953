.mat-select-panel.select-search-options {
    &>mat-option {
        &>.mat-option-text {
            &>.subtitle {
                color: $text-muted;
                font-size: .9em;
                margin-top: -5px;
                padding-bottom: 10px;
                line-height: normal;
            }
        }
    }
}
