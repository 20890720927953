app-google-map {
    .map-layer-selector {
        background-color: $white;
        border-radius: 0.25rem;
        padding-top: 5px;
        padding-bottom: 10px;

        .mat-form-field-wrapper {
            padding-bottom: 0;
        }

        .mat-form-field-appearance-legacy .mat-form-field-underline {
            display: none;
        }

        .mat-form-field-prefix,
        .mat-form-field-suffix {
            .mat-icon {
                line-height: 33px;
            }
        }

        .mat-form-field-infix {
            border-top: 0.54375em solid transparent;
        }
    }
}
