// https://www.google.com/design/spec/components/tabs.html#tabs-specs

.nav-link {
    //line-height: $bmd-nav-link-line-height; // set baseline line-height and font sizes
    text-transform: uppercase;
}

// navbars
.navbar-nav {
    .nav-link {
        padding: $bmd-navbar-link-padding;
        font-size: $bmd-navbar-link-font-size;
        font-weight: $bmd-navbar-link-font-weight;
    }
}

//pills and tabs
.nav-tabs,
.nav-pills {
    border: 0;
    border-radius: 3px;
    padding: 0 15px;

    .nav-link {
        padding: $bmd-nav-tabs-pills-link-padding;
        font-size: $bmd-nav-tabs-pills-font-size;
        font-weight: $bmd-nav-tabs-pills-font-weight;
        border: 0;
    }
}

// used to correctly size ripple container
.nav {
    .nav-item {
        position: relative;
    }
}
