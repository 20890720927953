ngx-mat-timepicker-toggle {
    .mat-mini-fab.mat-button-disabled.mat-button-disabled {
        background-color: transparent;
    }

    .mdc-icon-button {
        font-size: inherit;
        margin: 0;
        padding: 0;
        height: 1.5em;
        width: 1.5em;
        bottom: -5px;
        &:disabled {
            color: #00000042;
        }
    }
}

ngx-mat-timepicker-content {
    .mat-mdc-mini-fab.mat-primary {
        background-color: $brand-primary;
        color: #fff;
    }
}
