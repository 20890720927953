app-view-switcher {
    .mat-form-field-appearance-outline {
        width: 150px !important;
        margin-top: -10px;
        .mat-form-field-wrapper {
            margin: 0;
            padding: 0;
            .mat-form-field-infix {
                padding: 0.7em 0;
                .option-with-icon {
                    display: flex;
                    align-items: center;
                    mat-icon {
                        float: left;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
