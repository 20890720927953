// Implementation specification in https://github.com/FezVrasta/bootstrap-material-design/issues/822

// Drawer.
.bmd-layout-drawer {
    position: absolute;
    z-index: 5; // over a header and content
    box-sizing: border-box;
    display: flex;

    flex-direction: column;
    flex-wrap: nowrap;

    max-height: 100%;
    overflow: visible;
    overflow-y: auto;
    font-size: 0.875rem;
    //color: $bmd-layout-drawer-text-color;
    //background: $bmd-layout-drawer-bg-color;

    // Transform offscreen.
    transition: transform;
    will-change: transform;
    transform-style: preserve-3d;
    @include box-shadow($bmd-shadow-2dp);
    @include material-animation-default();

    > * {
        flex-shrink: 0;
    }

    // Placement of common components within the drawer
    //  You might say this is opinionated - feedback welcome.
    > header {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 1rem;

        .navbar-brand {
            padding-left: 1.5rem;
            font-size: 1.25rem;
        }
    }

    > .list-group {
        padding-top: 1rem;

        .list-group-item {
            padding-right: 2.5rem;
            padding-left: 2.5rem;
            font-size: 0.8125rem;
            font-weight: 500;
        }
    }
}

//----------------
// Sizing and positioning below here

// for left or right drawers, setup widths, heights and positions
@include bmd-drawer-x-out($bmd-drawer-x-size);

// for top or bottom drawers, setup widths, heights and positions
@include bmd-drawer-y-out($bmd-drawer-y-size);

// Marker class for both triggering the opening of the drawer (i.e. javascript #addClass('.bmd-drawer-in')), as well
//  as responsive sizes (i.e. bmd-drawer-in-md will open once the browser is wider than 768px).
//
//
// bmd-drawer-in, bmd-drawer-in-sm, bmd-drawer-in-md, bmd-drawer-in-lg
//  We iterate over the standard grid breakpoints, but we also need styles for the simpler `bmd-drawer-in`
//  without a size (for triggered openings).  Since the xs size is 0, instead of using `bmd-drawer-in-xs`
//  (which is mostly meaningless), we create `bmd-drawer-in` meaning always open.
//
//  NOTE: bmd-drawer-in-* uses media-breakpoint-up()
//
// bmd-drawer-out
//  A marker class that will _force_ the drawer closed regardless of the responsive class present. If (for some
//  strange reason) both bmd-drawer-out and bmd-drawer-in are present, it appears that the closed option wins.
//
// bmd-drawer-overlay, bmd-drawer-overlay-sm, bmd-drawer-overlay-md, bmd-drawer-overlay-lg
//  We iterate over the standard grid breakpoints, but we also need styles for the simpler `bmd-drawer-overlay`.
//  Since the xs size is 0, instead of using `bmd-drawer-overlay-xs`, we create `bmd-drawer-overlay` meaning always overlay.
//
//  NOTE: bmd-drawer-overlay-* uses media-breakpoint-down() - this is because we usually want a responsive overlay to be used in small screen scenarios.
//

//
// Drawer open positioning, and style options (push is default, overlay is optional)
:not(.bmd-drawer-out) {
    // first eliminate positioning or sizing rules if the drawer is already forced closed
    @each $breakpoint in map-keys($grid-breakpoints) {
        @include bmd-drawer-x-in-up($bmd-drawer-x-size, $breakpoint);
        @include bmd-drawer-y-in-up($bmd-drawer-y-size, $breakpoint);

        @include bmd-drawer-x-overlay-down($breakpoint);
        @include bmd-drawer-y-overlay-down($breakpoint);
    }
}

////----
//// Example 1: creates a media responsive kitchen-sink-drawer that pairs with bmd-drawer-in-lg and allows for the bmd-drawer-in trigger
//.kitchen-sink-drawer {
//  $custom-size: 500px;
//  @include bmd-drawer-x-out($custom-size);
//  &:not(.bmd-drawer-out) {
//    @each $breakpoint in map-keys($grid-breakpoints) {
//      @include bmd-drawer-x-in-up($custom-size, $breakpoint);
//    }
//  }
//}
//
////
////----
//// Example 2: creates a NON-responsive kitchen-sink-drawer and allows for the bmd-drawer-in trigger
//.kitchen-sink-drawer-static {
//  $custom-size: 500px;
//  @include bmd-drawer-x-out($custom-size); // closed by default
//  @include bmd-drawer-x-in($custom-size); // triggered with bmd-drawer-in
//  @include bmd-drawer-x-overlay(); // overlay by default, no other classes necessary
//}
